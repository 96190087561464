import React, { useEffect } from 'react';
import Card from '@/components/Card';
import Input from '@/components/Input';
import { Formik, Form, getIn } from 'formik';
import * as Yup from 'yup';
import Button from '@/components/Button';
import Select from '@/components/Select';
import { AddPropertyValues, PropertyStatus, PropertyType } from '@/types';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProperty,
  isPropertyCreatedSelector,
  propertyLoadingSelector,
  setIsPropertyCreated,
  setProperty,
} from '@/store/property/propertySlices';
import { useNavigate } from 'react-router-dom';
import './AddProperty.scss';

const baseClass = 'AddProperty';

// Validation Schema with Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  rentPrice: Yup.number().required('Rent price is required'),
  type: Yup.string()
    .oneOf(['residential', 'commercial'])
    .required('Type is required'),
  status: Yup.string()
    .oneOf(['available', 'rented'])
    .required('Status is required'),
  address: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    state: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    city: Yup.string().required('City is required'),
    street: Yup.string().required('Street is required'),
    houseNumber: Yup.string().required('House number is required'),
    apartmentNumber: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    zipCode: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    phone: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    latitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    longitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
  }),
});

const initialValues: AddPropertyValues = {
  name: '',
  rentPrice: 0,
  type: PropertyType.residential,
  status: PropertyStatus.available,
  address: {
    country: '',
    state: null,
    city: '',
    street: '',
    houseNumber: '',
    apartmentNumber: '',
    phone: null,
    zipCode: null,
    latitude: null,
    longitude: null,
  },
};

const AddProperty = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(propertyLoadingSelector);
  const navigate = useNavigate();
  const isPropertyCreated = useSelector(isPropertyCreatedSelector);

  useEffect(() => {
    return () => {
      dispatch(setProperty(null));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isPropertyCreated) {
      dispatch(setIsPropertyCreated(false));
      navigate('..', { relative: 'path' });
    }
  }, [isPropertyCreated, dispatch, navigate]);

  return (
    <Card className={baseClass}>
      <Card.Title>Add Property</Card.Title>
      <Card.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            dispatch(createProperty(values));
            resetForm();
          }}
        >
          {({
            values,
            errors,
            isSubmitting,
            handleBlur,
            handleChange,
            touched,
            isValid,
            dirty,
          }) => {
            return (
              <Form className={`${baseClass}-form`}>
                <div className={`${baseClass}-form-group`}>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'Name*'}
                      placeholder={'Name'}
                      name={'name'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      // errors={errors.name && touched.name ? errors.name : ''}
                      errors={
                        getIn(errors, 'name') && getIn(touched, 'name')
                          ? getIn(errors, 'name')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'Country*'}
                      placeholder={'Country'}
                      name={'address.country'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.country}
                      // errors={
                      //   errors.country && touched.country ? errors.country : ''
                      // }
                      errors={
                        getIn(errors, 'address.country') &&
                        getIn(touched, 'address.country')
                          ? getIn(errors, 'address.country')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'State'}
                      placeholder={'State'}
                      name={'address.state'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.state}
                      // errors={errors.state && touched.state ? errors.state : ''}
                      errors={
                        getIn(errors, 'address.state') &&
                        getIn(touched, 'address.state')
                          ? getIn(errors, 'address.state')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'City*'}
                      placeholder={'City'}
                      name={'address.city'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.city}
                      // errors={errors.city && touched.city ? errors.city : ''}
                      errors={
                        getIn(errors, 'address.city') &&
                        getIn(touched, 'address.city')
                          ? getIn(errors, 'address.city')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'Street*'}
                      placeholder={'Street'}
                      name={'address.street'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.street}
                      // errors={
                      //   errors.street && touched.street ? errors.street : ''
                      // }
                      errors={
                        getIn(errors, 'address.street') &&
                        getIn(touched, 'address.street')
                          ? getIn(errors, 'address.street')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'House number*'}
                      placeholder={'House number'}
                      name={'address.houseNumber'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.houseNumber}
                      errors={
                        getIn(errors, 'address.houseNumber') &&
                        getIn(touched, 'address.houseNumber')
                          ? getIn(errors, 'address.houseNumber')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'Apartment number'}
                      placeholder={'Apartment number'}
                      name={'address.apartmentNumber'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.apartmentNumber}
                      errors={
                        getIn(errors, 'address.apartmentNumber') &&
                        getIn(touched, 'address.apartmentNumber')
                          ? getIn(errors, 'address.apartmentNumber')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      type={'number'}
                      label={'Rent Price*'}
                      placeholder={'Rent Price'}
                      name={'rentPrice'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rentPrice}
                      errors={
                        getIn(errors, 'rentPrice') &&
                        getIn(touched, 'rentPrice')
                      }
                    />
                  </div>

                  <div className={`${baseClass}-form-group-item`}>
                    <Select
                      className={`${baseClass}-form-group-item--select`}
                      label={'Type'}
                      // placeholder={'Type'}
                      name={'type'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                      errors={
                        getIn(errors, 'type') && getIn(touched, 'type')
                          ? getIn(errors, 'type')
                          : ''
                      }
                      options={[
                        { label: 'Residential', value: 'residential' },
                        { label: 'Commercial', value: 'commercial' },
                      ]}
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Select
                      className={`${baseClass}-form-group-item--select`}
                      label={'Status'}
                      // placeholder={'Status'}
                      name={'status'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.status}
                      errors={
                        getIn(errors, 'status') && getIn(touched, 'status')
                          ? getIn(errors, 'status')
                          : ''
                      }
                      options={[
                        { label: 'Available', value: 'available' },
                        { label: 'Rented', value: 'rented' },
                      ]}
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      type={'number'}
                      label={'Zip Code'}
                      placeholder={'Zip Code'}
                      name={'address.zipCode'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.zipCode}
                      errors={
                        getIn(errors, 'address.zipCode') &&
                        getIn(touched, 'address.zipCode')
                          ? getIn(errors, 'address.zipCode')
                          : ''
                      }
                    />
                  </div>
                  <div className={`${baseClass}-form-group-item`}>
                    <Input
                      className={`${baseClass}-form-group-item--input`}
                      label={'Phone'}
                      placeholder={'Phone'}
                      name={'address.phone'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address.phone}
                      // errors={
                      //   errors.zipCode && touched.zipCode ? errors.zipCode : ''
                      // }
                      errors={
                        getIn(errors, 'address.phone') &&
                        getIn(touched, 'address.phone')
                          ? getIn(errors, 'address.phone')
                          : ''
                      }
                    />
                  </div>
                </div>
                <div className={`${baseClass}-form-btns`}>
                  <Button
                    type="submit"
                    isDisabled={isLoading || isSubmitting || !isValid}
                    isLoading={isLoading}
                  >
                    Add
                  </Button>
                  <Button type="reset" theme={'secondary'} isDisabled={!dirty}>
                    Reset
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default AddProperty;
