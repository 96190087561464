import axios from 'axios';

function getAxiosErrors(error: unknown): { [key: string]: any } {
  if (axios.isAxiosError(error)) {
    if (error.response?.data?.errors) {
      return error.response.data.errors;
    } else if (error.response?.data?.message) {
      return { errorMessage: [error.response.data.message] };
    } else {
      return { errorMessage: ['Unknown error'] };
    }
  } else {
    return { errorMessage: [(error as Error).message] };
  }
}
export default getAxiosErrors;
