import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

function handleRequestErrors(
  error: unknown,
  action: (val: {
    [key: string]: string[];
  }) => PayloadAction<{ [key: string]: string[] }>,
) {
  if (axios.isAxiosError(error)) {
    if (error.response?.data?.errors) {
      return action(error.response.data.errors);
    } else if (error.response?.data?.message) {
      return action({ errorMessage: [error.response.data.message] });
    } else {
      return action({ errorMessage: ['Unknown error'] });
    }
  } else {
    return action({ errorMessage: [(error as Error).message] });
  }
}

export default handleRequestErrors;
