import React, { useEffect } from 'react';
import './InvitePage.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { linkPropertyRequest } from '@/api';
import axios from 'axios';
import { accessTokenSelector } from '@/store/auth/authSlices';
import { useSelector } from 'react-redux';

const baseClass = 'InvitePage';

const InvitePage = () => {
  const { token } = useParams();
  const accessToken = useSelector(accessTokenSelector);
  const navigate = useNavigate();

  useEffect(() => {
    // const linkProperty = async (token: string) => {
    //   try {
    //     const response = await linkPropertyRequest(token);
    //     console.log(response.data);
    //   } catch (e: unknown) {
    //     console.log(e);
    //     if (axios.isAxiosError(e)) {
    //       const errorData = e.response?.data;
    //       if (errorData) {
    //         const { error, message, statusCode } = errorData;
    //         console.log(message, statusCode);
    //         if (statusCode === 400) {
    //           // navigate(`/properties`);
    //         }
    //       }
    //     } else {
    //       console.log('Error');
    //     }
    //   }
    // };
    //
    // if (token) {
    //   linkProperty(token);
    // }
    if (!token) return;

    if (accessToken) {
      linkPropertyRequest(token)
        .then((response) => {
          console.log(response.data);
          navigate(response.data.redirectUrl || `/properties`);
        })
        .catch((e) => {
          console.log(e);
          if (axios.isAxiosError(e)) {
            const errorData = e.response?.data;
            if (errorData) {
              const { message, statusCode } = errorData;
              console.log(message, statusCode);
              if (statusCode === 400 || statusCode === 404) {
                navigate(`/properties`);
              }
            }
          } else {
            console.log('Error');
          }
        });
    } else {
      // navigate(`/login?inviteToken=${token}`);
      navigate(`/register?inviteToken=${token}`);
    }
  }, [token, accessToken, navigate]);
  return <div className={baseClass}>Invite page: {token}</div>;
};

export default InvitePage;
