import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteInvites,
  deleteTenant,
  deletingProperty,
  fetchingProperty,
  isPropertyDeletedSelector,
  isPropertyUpdatedSelector,
  propertyLoadingSelector,
  propertySelector,
  setIsPropertyDeleted,
  setIsPropertyUpdated,
  setProperty,
  updatingProperty,
} from '@/store/property/propertySlices';
import Card from '@/components/Card';
import Button from '@/components/Button';
import Skeleton from '@/components/Skeleton';
import Input from '@/components/Input';
import { Form, Formik, getIn } from 'formik';
import { AddPropertyValues, PropertyStatus, PropertyType } from '@/types';
import * as Yup from 'yup';
import Select from '@/components/Select';
import {
  createInviteRequest,
  deleteInviteRequest,
  deleteTenantRequest,
  FRONTEND_URL,
} from '@/api';
import './EditProperty.scss';
import { profileSelector } from '@/store/profile/profileSlices';

const baseClass = 'EditProperty';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  rentPrice: Yup.number().required('Rent price is required'),
  type: Yup.string()
    .oneOf(['residential', 'commercial'])
    .required('Type is required'),
  status: Yup.string()
    .oneOf(['available', 'rented'])
    .required('Status is required'),
  address: Yup.object().shape({
    country: Yup.string().required('Country is required'),
    state: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    city: Yup.string().required('City is required'),
    street: Yup.string().required('Street is required'),
    houseNumber: Yup.string().required('House number is required'),
    apartmentNumber: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    zipCode: Yup.number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null
    phone: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    latitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
    longitude: Yup.string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value,
      ), // Преобразование пустых строк в null,
  }),
});

const initialValues: AddPropertyValues = {
  name: '',
  rentPrice: 0,
  type: PropertyType.residential,
  status: PropertyStatus.available,
  address: {
    country: '',
    state: null,
    city: '',
    street: '',
    houseNumber: '',
    apartmentNumber: null,
    phone: null,
    zipCode: null,
    latitude: null,
    longitude: null,
  },
};

const EditProperty = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useSelector(propertySelector);
  const profile = useSelector(profileSelector);
  const isPropertyLoading = useSelector(propertyLoadingSelector);
  const isPropertyUpdated = useSelector(isPropertyUpdatedSelector);
  const isPropertyDeleted = useSelector(isPropertyDeletedSelector);
  const numericId = Number(id);
  const isNumeric = !isNaN(numericId);
  const [isInviteLoading, setIsInviteLoading] = useState(false);

  const deleteInviteHandler = useCallback(async () => {
    setIsInviteLoading(true);
    if (property) {
      deleteInviteRequest(property.id)
        .then((res) => {
          console.log('DeleteInvite', res);
          dispatch(deleteInvites());
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsInviteLoading(false);
        });
    }
  }, [property, dispatch]);

  const deleteTenantHandler = useCallback(async () => {
    if (property?.tenant?.id) {
      setIsInviteLoading(true);
      deleteTenantRequest(property.id, property.tenant.id)
        .then(() => {
          dispatch(deleteTenant());
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsInviteLoading(false);
        });
    }
  }, [property, dispatch]);

  const generateInviteHandler = useCallback(async () => {
    setIsInviteLoading(true);
    createInviteRequest(numericId)
      .then((res) => {
        dispatch(setProperty(res.data));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsInviteLoading(false);
      });
  }, [numericId, dispatch]);

  const deleteProperty = () => {
    dispatch(deletingProperty(numericId));
  };

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Copied to clipboard');
      },
      (err) => {
        console.error('Failed to copy: ', err);
      },
    );
  }, []);

  useEffect(() => {
    if (property?.owner && profile?.id) {
      if (property?.owner?.id !== profile?.id) {
        navigate('..', { relative: 'path' });
      }
    }
  }, [navigate, property, profile]);

  useEffect(() => {
    if (isPropertyUpdated) {
      dispatch(setIsPropertyUpdated(false));
      navigate('..', { relative: 'path' });
    }
  }, [isPropertyUpdated, dispatch, navigate]);

  useEffect(() => {
    if (isPropertyDeleted) {
      navigate('/properties');
    }
  }, [isPropertyDeleted, navigate]);

  useEffect(() => {
    if (isNumeric) {
      dispatch(fetchingProperty(numericId));
    }
  }, [dispatch, numericId, isNumeric]);

  useEffect(() => {
    // Возвращаем функцию очистки, которая будет вызвана при размонтировании компонента
    return () => {
      dispatch(setIsPropertyDeleted(false));
      dispatch(setIsPropertyUpdated(false));
    };
  }, [dispatch]);

  if (!isNumeric || (!isPropertyLoading && !property)) {
    return (
      <Card className={baseClass}>
        <h4>Invalid property ID</h4>
        <Button onClick={() => navigate('/properties')}>
          Go back to properties
        </Button>
      </Card>
    );
  }
  return (
    <Card className={baseClass}>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={property || initialValues}
        onSubmit={(values) => {
          dispatch(updatingProperty({ id: numericId, data: values }));
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          touched,
        }) => {
          return (
            <Form className={`${baseClass}-form`}>
              <Card.Header className={`${baseClass}--header`}>
                <Card.Title className={`${baseClass}--title`}>
                  <Skeleton isLoading={isPropertyLoading}>
                    {property?.name}
                  </Skeleton>
                  <div className={`${baseClass}--title-btns`}>
                    <Button
                      className={`${baseClass}--title-btns-cancel`}
                      onClick={() => navigate('..', { relative: 'path' })}
                    >
                      Cancel
                    </Button>
                    <Button
                      className={`${baseClass}--title-btns-delete`}
                      onClick={deleteProperty}
                      theme={'danger'}
                    >
                      Delete
                    </Button>
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body className={`${baseClass}--body`}>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    label={'Name*'}
                    floatLabel={true}
                    placeholder={'Name'}
                    name={'name'}
                    direction={'row'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    // errors={errors.name && touched.name ? errors.name : ''}
                    errors={
                      getIn(errors, 'name') && getIn(touched, 'name')
                        ? getIn(errors, 'name')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'Country*'}
                    floatLabel={true}
                    placeholder={'Country'}
                    name={'address.country'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.country}
                    errors={
                      getIn(errors, 'address.country') &&
                      getIn(touched, 'address.country')
                        ? getIn(errors, 'address.country')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'State'}
                    floatLabel={true}
                    placeholder={'State'}
                    name={'address.state'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.state}
                    errors={
                      getIn(errors, 'address.state') &&
                      getIn(touched, 'address.state')
                        ? getIn(errors, 'address.state')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'City*'}
                    floatLabel={true}
                    placeholder={'City'}
                    name={'address.city'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.city}
                    errors={
                      getIn(errors, 'address.city') &&
                      getIn(touched, 'address.city')
                        ? getIn(errors, 'address.city')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'Street*'}
                    floatLabel={true}
                    placeholder={'Street'}
                    name={'address.street'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.street}
                    errors={
                      getIn(errors, 'address.street') &&
                      getIn(touched, 'address.street')
                        ? getIn(errors, 'address.street')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'House number'}
                    floatLabel={true}
                    placeholder={'House number'}
                    name={'address.houseNumber'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.houseNumber}
                    errors={
                      getIn(errors, 'address.houseNumber') &&
                      getIn(touched, 'address.houseNumber')
                        ? getIn(errors, 'address.houseNumber')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    label={'Apartment number'}
                    floatLabel={true}
                    placeholder={'Apartment number'}
                    name={'address.apartmentNumber'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.apartmentNumber}
                    errors={
                      getIn(errors, 'address.apartmentNumber') &&
                      getIn(touched, 'address.apartmentNumber')
                        ? getIn(errors, 'address.apartmentNumber')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    type={'number'}
                    label={'Rent Price*'}
                    floatLabel={true}
                    placeholder={'Rent Price'}
                    name={'rentPrice'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rentPrice}
                    errors={
                      getIn(errors, 'rentPrice') && getIn(touched, 'rentPrice')
                        ? getIn(errors, 'rentPrice')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Select
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    label={'Type'}
                    floatLabel
                    direction={'row'}
                    name={'type'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    errors={
                      getIn(errors, 'type') && getIn(touched, 'type')
                        ? getIn(errors, 'type')
                        : ''
                    }
                    options={[
                      { label: 'Residential', value: 'residential' },
                      { label: 'Commercial', value: 'commercial' },
                    ]}
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Select
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    label={'Status'}
                    floatLabel
                    direction={'row'}
                    name={'status'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status}
                    errors={
                      getIn(errors, 'status') && getIn(touched, 'status')
                        ? getIn(errors, 'status')
                        : ''
                    }
                    options={[
                      { label: 'Available', value: 'available' },
                      { label: 'Rented', value: 'rented' },
                    ]}
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}-form-group-item--input`}
                    type={'number'}
                    label={'Zip Code'}
                    floatLabel
                    placeholder={'Zip Code'}
                    name={'address.zipCode'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address.zipCode}
                    errors={
                      getIn(errors, 'address.zipCode') &&
                      getIn(touched, 'address.zipCode')
                        ? getIn(errors, 'address.zipCode')
                        : ''
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <Input
                    className={`${baseClass}--row`}
                    labelClassName={`${baseClass}--row-label`}
                    direction={'row'}
                    type={'tel'}
                    label={'Phone'}
                    floatLabel
                    placeholder={'Phone'}
                    name={'address.phone'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address?.phone}
                    errors={
                      getIn(errors, 'address.phone') &&
                      getIn(touched, 'address.phone')
                    }
                  />
                </Skeleton>
                <Skeleton isLoading={isPropertyLoading}>
                  <div className={`${baseClass}--row`}>
                    <span className={`${baseClass}--row-label`}>Tenant:</span>
                    <span className={`${baseClass}--row-value `}>
                      {property?.tenant && (
                        <span className={`${baseClass}--invites`}>
                          <span>{property?.tenant?.username}</span>
                          <Button
                            theme={'danger'}
                            onClick={deleteTenantHandler}
                            isLoading={isInviteLoading}
                          >
                            Delete tenant
                          </Button>
                        </span>
                      )}
                      {property?.invites &&
                        property.invites.map((invite, idx) => {
                          return (
                            <span
                              key={invite.id}
                              className={`${baseClass}--invites`}
                            >
                              <span className={`${baseClass}--invites-title`}>
                                {`Invite link ${idx + 1}`}
                              </span>
                              <Button
                                // theme={'danger'}
                                onClick={() => {
                                  copyToClipboard(
                                    `${FRONTEND_URL}/invite/${invite.token}`,
                                  );
                                }}
                                isLoading={isInviteLoading}
                              >
                                Copy
                              </Button>
                              <Button
                                theme={'danger'}
                                onClick={deleteInviteHandler}
                                isLoading={isInviteLoading}
                              >
                                Delete
                              </Button>
                            </span>
                          );
                        })}
                      {!property?.tenant && !property?.invites?.length && (
                        <span className={`${baseClass}--invites`}>
                          <span>No tenant</span>
                          <Button
                            theme={'secondary'}
                            onClick={generateInviteHandler}
                            isLoading={isInviteLoading}
                          >
                            Generate invite
                          </Button>
                        </span>
                      )}
                    </span>
                  </div>
                </Skeleton>
                <Button type="submit" isLoading={isPropertyLoading}>
                  Save
                </Button>
              </Card.Body>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default EditProperty;
