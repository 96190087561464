import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import './Select.scss';

const baseClass = 'Select';

interface SelectProps {
  className?: string;
  value: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  direction?: 'row' | 'column';
  name?: string;
  id?: string | number;
  options: { label: string; value: string }[];
  errors?: string | string[];
  theme?: 'light' | 'dark';
  status?: 'success' | 'error' | 'warning' | 'default';
  isDisabled?: boolean;
  labelClassName?: string;
  floatLabel?: boolean;
}

const Select: FC<SelectProps> = ({
  className,
  value = '',
  onChange,
  onBlur,
  label = '',
  direction = 'column',
  name,
  status = 'default',
  theme = 'light',
  options = [],
  errors,
  isDisabled = false,
  labelClassName = '',
  floatLabel = false,
}) => {
  const selectRef = useRef<HTMLSelectElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const focusSelect = () => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={clsx(
        baseClass,
        `${baseClass}-${direction}`,
        `${baseClass}-${status}`,
        `${baseClass}-${theme}`,
        className,
        className ? `${className}-${direction}` : '',
      )}
    >
      {label && (
        <label
          onClick={focusSelect}
          className={clsx(
            `${baseClass}-label`,
            className ? `${className}-label` : '',
            {
              [`${baseClass}-label--error`]: errors,
              [`${baseClass}-label--float`]: floatLabel,
              [`${baseClass}-label--float-active`]:
                floatLabel && (isFocused || value),
            },
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          `${baseClass}-wrapper`,
          className ? `${className}-wrapper` : '',
          { [`${baseClass}-wrapper--error`]: errors },
        )}
      >
        <select
          ref={selectRef}
          className={clsx(
            `${baseClass}-select`,
            className ? `${className}-select` : '',
            className ? `${className}-select-${theme}` : '',
            `${baseClass}-select-${theme}`,
            { [`${baseClass}-select--error`]: errors },
          )}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name={name}
          disabled={isDisabled}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {errors && (
          <div className={`${baseClass}-error`}>
            {Array.isArray(errors) ? errors.join(', ') : errors}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
