import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import logo from '@/img/propsavvy-logo-with-text.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  propertyIdSelector,
  setAuthPropertyId,
  setAuthTokens,
} from '@/store/auth/authSlices';
import * as Yup from 'yup';
import { Form, Formik, getIn } from 'formik';
import Input from '@/components/Input';
import Button from '@/components/Button';
import { signinRequest } from '@/api';
import getAxiosErrors from '@/utils/getAxiosErrors';
import './LoginStyles.scss';
import { fetchProfileSuccess } from '@/store/profile/profileSlices';

const baseClass = 'login-page';

interface IValues {
  username: string;
  password: string;
  errorMessage?: string[];
}
const initialValues: IValues = {
  username: '',
  password: '',
  errorMessage: [],
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required').min(3),
  password: Yup.string().required('Password is required').min(4),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Хук для перенаправления
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('inviteToken');
  const propertyId = useSelector(propertyIdSelector);

  // Перенаправление при успешной аутентификации
  React.useEffect(() => {
    if (isAuthenticated && propertyId) {
      navigate(`/properties/${propertyId}`);
    } else if (isAuthenticated && !propertyId) {
      navigate('/'); // Перенаправление на главную страницу
    }
  }, [isAuthenticated, navigate, propertyId]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-view`}>
        <div className={`${baseClass}-view-logo`}>
          <img src={logo} alt="logo" />
          <p className={`${baseClass}-view-logo-desc`}>
            Please enter your user information.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const data = inviteToken ? { ...values, inviteToken } : values;
            signinRequest(data)
              .then((res) => {
                console.log(res.data);
                const { accessToken, user, propertyId } = res.data;
                dispatch(setAuthTokens(accessToken));
                dispatch(setAuthPropertyId(propertyId));
                dispatch(fetchProfileSuccess(user));
              })
              .catch((err) => {
                setErrors(getAxiosErrors(err));
                console.log('loginErrors', getAxiosErrors(err));
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            isSubmitting,
          }) => (
            <Form className={`${baseClass}-view-form`}>
              <div className={`${baseClass}-view-form-group`}>
                <Input
                  floatLabel
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  name="username"
                  type="text"
                  label="Username"
                  errors={
                    getIn(errors, 'username') && getIn(touched, 'username')
                      ? getIn(errors, 'username')
                      : ''
                  }
                />
                <Input
                  floatLabel
                  className={`${baseClass}-view-form-group-password`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  name="password"
                  type="password"
                  label="Password"
                  errors={
                    getIn(errors, 'password') && getIn(touched, 'password')
                      ? getIn(errors, 'password')
                      : ''
                  }
                />
                <div className={`${baseClass}--error`}>
                  {errors.errorMessage}
                </div>
                <Button
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  type="submit"
                  className={`${baseClass}-view-form-group-submit-btn`}
                >
                  {isSubmitting ? 'Logging...' : 'Login'}
                </Button>
              </div>

              <div className={`${baseClass}-view-form-group-bottom`}>
                <Link
                  to={`/register${inviteToken ? `?inviteToken=${inviteToken}` : ''}`}
                  className={`${baseClass}-view-form-group-bottom-create`}
                >
                  Create an account
                </Link>
                <Link
                  to={'/forgot'}
                  className={`${baseClass}-view-form-group-bottom-forgot`}
                >
                  Forgot password?
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Login;
