import React, { FC } from 'react';
import { IProperty } from '@/types';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@/components/Skeleton';

const baseClass = 'PropertyTable';

const PropertyRow: FC<IProperty> = ({ id, name, address, type, status }) => {
  const navigate = useNavigate();

  return (
    <tr
      className={status === 'available' ? 'available' : ''}
      onClick={() => {
        navigate(`/properties/${id}`);
      }}
    >
      <td>
        <div className="property-name">
          {name}
          <span
            className={`property-type ${type === 'residential' ? 'residential' : 'commercial'}`}
          >
            {type === 'residential' ? 'Residential' : 'Commercial'}
          </span>
        </div>
      </td>
      <td>
        {address?.country}, {address?.city}, {address?.street},{' '}
        {address?.houseNumber}
      </td>
      <td>{address?.apartmentNumber}</td>
    </tr>
  );
};

// Компонент для всей таблицы
const PropertyTable: FC<{ properties: IProperty[]; isLoading: boolean }> = ({
  properties,
  isLoading = false,
}) => {
  return (
    <table className="property-table">
      <thead>
        <tr>
          <th>NAME</th>
          <th>ADDRESS</th>
          <th>APT</th>
          {/*<th>STATUS</th>*/}
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <>
            <tr>
              <td colSpan={3} className="loading">
                <Skeleton
                  isLoading={isLoading}
                  style={{ width: '100%' }}
                ></Skeleton>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="loading">
                <Skeleton
                  isLoading={isLoading}
                  style={{ width: '100%' }}
                ></Skeleton>
              </td>
            </tr>
            <tr>
              <td colSpan={3} className="loading">
                <Skeleton
                  isLoading={isLoading}
                  style={{ width: '100%' }}
                ></Skeleton>
              </td>
            </tr>
          </>
        ) : (
          properties.map((property) => (
            <PropertyRow key={property.id} {...property} />
          ))
        )}
      </tbody>
      {/*<tbody>*/}
      {/*  {properties.map((property) => (*/}
      {/*    <PropertyRow key={property.id} {...property} />*/}
      {/*  ))}*/}
      {/*</tbody>*/}
    </table>
  );
};

export default PropertyTable;
