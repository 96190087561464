import React, { FC, useEffect } from 'react';
import './PropertyProfileDialog.scss';
import Dialog from '@/components/Dialog';
import { getParticipantProfile } from '@/api';
import { IUser } from '@/types';
import { NavLink } from 'react-router-dom';

const baseClass = 'PropertyProfileDialog';

interface PropertyProfileDialogProps {
  userId?: number | null;
  onClose: () => void;
  onOpen?: () => void;
  propertyId?: number | null;
}

const PropertyProfileDialog: FC<PropertyProfileDialogProps> = ({
  userId,
  onClose,
  onOpen,
  propertyId,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [profile, setProfile] = React.useState<IUser | null>(null);

  useEffect(() => {
    if (userId && propertyId) {
      setProfile(null);
      setIsLoading(true);
      getParticipantProfile(propertyId, userId)
        .then((respone) => {
          console.log('participant profile', respone);
          if (respone.data) {
            setProfile(respone.data);
          }
        })
        .catch((error) => {
          console.log('participant profile error', error);
          setProfile(null);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [userId, propertyId]);

  return (
    <Dialog
      className={baseClass}
      isOpen={!!userId}
      onOpen={onOpen}
      onClose={onClose}
      position={'right'}
      title={profile?.username}
      isLoader={isLoading}
      // footer={<Button>Send</Button>}
    >
      {profile && (
        <>
          <div className={`${baseClass}--photo`}>
            {profile.avatar ? (
              <img
                src={profile.avatar}
                alt={profile.username}
                className={`${baseClass}--photo-img`}
              />
            ) : (
              <div>ddd</div>
            )}
          </div>
          <div className={`${baseClass}--name`}>
            {profile.firstname} {profile.lastname}
          </div>
          {profile.email && (
            <div className={`${baseClass}--email`}>
              <i
                className={`fa-regular fa-envelope ${baseClass}--email-icon`}
              ></i>
              <a
                href={`mailto:${profile.email}`}
                className={`${baseClass}--email-link`}
              >
                {profile.email}
              </a>
            </div>
          )}
          {profile.phone && (
            <div className={`${baseClass}--phone`}>
              <i className={`fa-solid fa-phone ${baseClass}--phone-icon`}></i>
              <a
                href={`tel:${profile.phone}`}
                className={`${baseClass}--phone-link`}
              >
                {profile.phone}
              </a>
            </div>
          )}
          <div className={`${baseClass}--phone`}>
            <i className={`fa-regular fa-message ${baseClass}--phone-chat`}></i>
            <NavLink
              to={`/chat/${userId}`}
              className={`${baseClass}--phone-link`}
            >
              Go to chat with {profile.username}
            </NavLink>
          </div>
        </>
      )}
    </Dialog>
  );
};

export default PropertyProfileDialog;
