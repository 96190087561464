import React, { useEffect } from 'react';
import Card from '@/components/Card';
import PropertyTable from '@/components/PropertyTable';
import Skeleton from '@/components/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProperties,
  ownedPropertiesSelector,
  propertiesLoadingSelector,
  tenantPropertiesSelector,
} from '@/store/properties/propertiesSlices';
import './HomePageStyles.scss';

const baseClass = 'homePage';

const Home = () => {
  const dispatch = useDispatch();
  const tenantProperties = useSelector(tenantPropertiesSelector);
  const ownedProperties = useSelector(ownedPropertiesSelector);
  const isLoading = useSelector(propertiesLoadingSelector);

  useEffect(() => {
    dispatch(fetchingProperties());
  }, [dispatch]);

  return (
    <div className={baseClass}>
      {/*<Card className={`${baseClass}-card`}>*/}
      {/*<Card.Title className={`${baseClass}-card-title`}>Home Page</Card.Title>*/}
      {/*<Card.Body className={`${baseClass}-card-body`}>*/}
      {isLoading &&
        ownedProperties.length === 0 &&
        tenantProperties.length === 0 && (
          <Card className={`${baseClass}--list`}>
            <Card.Title>Loading...</Card.Title>
            <Card.Body>
              <Skeleton isLoading={isLoading} style={{ width: '100%' }} />
            </Card.Body>
          </Card>
        )}
      {ownedProperties.length > 0 && (
        <Card className={`${baseClass}--list`}>
          <Card.Title>Your Rental Properties</Card.Title>
          <Card.Body>
            <PropertyTable properties={ownedProperties} isLoading={isLoading} />
          </Card.Body>
        </Card>
      )}
      {tenantProperties.length > 0 && (
        <Card className={`${baseClass}--list`}>
          <Card.Title>Your Rented Properties</Card.Title>
          <Card.Body>
            <PropertyTable
              properties={tenantProperties}
              isLoading={isLoading}
            />
          </Card.Body>
        </Card>
      )}
      {/*</Card.Body>*/}
      {/*</Card>*/}
    </div>
  );
};

export default Home;
