import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { accessTokenSelector } from '@/store/auth/authSlices';
import ProtectedRoute from '@/components/ProtectedRoute';
import Home from '@/components/Home';
import Login from '@/components/Login';
import Signup from '@/components/Signup';
import ForgotPassword from '@/components/ForgotPassword';
import Navbar from '@/components/Navbar/Navbar';
import Properties from '@/components/Properies';
import Property from '@/components/Property';
import AddProperty from '@/components/AddProperty';
import NotFoundPage from '@/components/NotFoundPage';
import EditProperty from '@/components/EditProperty';
import ConfirmEmail from '@/components/ConfirmEmail';
import InvitePage from '@/components/InvitePage';
import ProfilePage from '@/components/ProfilePage';
import ChatsPage from '@/components/ChatsPage';
import './App.scss';
import CalendarPage from '@/components/CalendarPage';

function App() {
  const accessToken = useSelector(accessTokenSelector);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isAuthenticated={!!accessToken}>
              <Navbar />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/properties/:id" element={<Property />} />
          <Route path="/properties/:id/edit" element={<EditProperty />} />
          <Route path="/properties/add" element={<AddProperty />} />
          <Route path="/properties" element={<Properties />} />
          <Route path="/transactions" element={<h1>Transactions page</h1>} />
          <Route path="/chats" element={<ChatsPage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/auth/confirm/:token" element={<ConfirmEmail />} />
        <Route path="/invite/:token" element={<InvitePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
