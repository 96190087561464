import React, { FC, useCallback, useEffect, useRef } from 'react';
import './ChatDialog.scss';
import { Chat } from '@/types';
import Dialog from '@/components/Dialog';
import Button from '@/components/Button';
import { getChatRequest, sendMessageRequest } from '@/api';
import Input from '@/components/Input';
import { useSelector } from 'react-redux';
import { profileSelector } from '@/store/profile/profileSlices';
import clsx from 'clsx';
import convertToLocaleString from '@/utils/convertToLocaleString';

const baseClass = 'ChatDialog';

interface ChatDialogProps {
  chatId?: number | null;
  onClose: () => void;
  onOpen?: () => void;
}

const ChatDialog: FC<ChatDialogProps> = ({ chatId, onOpen, onClose }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);
  const [chat, setChat] = React.useState<Chat | null>(null);
  const [message, setMessage] = React.useState('');
  const propfile = useSelector(profileSelector);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (chatId) {
      setChat(null);
      setIsLoading(true);
      getChatRequest(chatId)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            setChat(response.data);
          } else {
            console.error('Error');
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [chatId]);

  const sendMessage = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (chatId) {
        setMessage('');
        setIsSending(true);
        sendMessageRequest(chatId, { text: message })
          .then((response) => {
            if (response.data) {
              console.log(response.data);
              // setChat((prevChat) => ({
              //   ...prevChat,
              //   messages: [...(prevChat?.messages || []), response.data],
              // }));
              setChat((prevChat) => {
                return {
                  ...prevChat,
                  messages: [...(prevChat?.messages || []), response.data],
                } as Chat;
              });
            } else {
              console.error('Error');
            }
          })
          .catch((error) => {})
          .finally(() => {
            setIsSending(false);
          });
      }
    },
    [message, chatId],
  );

  // Прокрутка к последнему сообщению после обновления чата
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chat]);

  return (
    <Dialog
      className={baseClass}
      isOpen={!!chatId}
      onOpen={onOpen}
      onClose={onClose}
      position={'right'}
      title={chat?.title}
      isLoader={isLoading}
      footer={
        <form className={`${baseClass}--form`} onSubmit={sendMessage}>
          <Input
            className={`${baseClass}--input`}
            placeholder="Type your message"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <Button
            className={`${baseClass}--button`}
            // onClick={sendMessage}
            isDisabled={isSending}
            type={'submit'}
          >
            Send
          </Button>
        </form>
      }
    >
      {chat && propfile && (
        <div className={`${baseClass}--list `}>
          {chat.messages.map((message) => (
            <div
              key={message.id}
              // className={`${baseClass}--list-item`}
              className={clsx(`${baseClass}--list-item`, {
                [`${baseClass}--list-item-sender`]:
                  message.sender.id === propfile.id,
              })}
            >
              <span className={`${baseClass}--list-item-name`}>
                {message.sender.username}
              </span>
              <span className={`${baseClass}--list-item-text`}>
                {message.text}
              </span>
              <span className={`${baseClass}--list-item-date`}>
                {convertToLocaleString(message.createdAt)}
              </span>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      )}
    </Dialog>
  );
};

export default ChatDialog;
