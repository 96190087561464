import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import './InputStyles.scss';

const baseClass = 'Input';

interface InputProps {
  className?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel';
  placeholder?: string;
  value?: string | number | undefined | null;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Измените здесь
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  direction?: 'row' | 'column';
  name?: string;
  id?: string | number;
  errors?: string | string[];
  theme?: 'light' | 'dark';
  status?: 'success' | 'error' | 'warning' | 'default';
  isDisabled?: boolean;
  step?: number;
  min?: number;
  max?: number;
  labelClassName?: string;
  floatLabel?: boolean;
  isLoading?: boolean;
}

const Input: FC<InputProps> = ({
  className,
  type = 'text',
  placeholder = '',
  value = '',
  onChange,
  onBlur,
  label = '',
  direction = 'column',
  name,
  status = 'default',
  theme = 'light',
  errors,
  isDisabled = false,
  step,
  min,
  max,
  labelClassName,
  floatLabel = false,
  isLoading = false,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = () => setIsFocused(true);
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (onBlur) onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (type === 'number') {
      if (/^\d*$/.test(newValue) || newValue === '') {
        onChange(e); // Здесь измените на newValue
      }
    } else {
      onChange(e); // Здесь измените на newValue
    }
  };

  return (
    <div
      className={clsx(
        baseClass,
        `${baseClass}-${direction}`,
        `${baseClass}-${status}`,
        `${baseClass}-${theme}`,
        className,
        className ? `${className}-${direction}` : '',
      )}
    >
      {label && (
        <label
          onClick={focusInput}
          className={clsx(
            `${baseClass}-label`,
            className ? `${className}-label` : '',
            {
              [`${baseClass}-label--error`]: errors,
              [`${baseClass}-label--float`]: floatLabel,
              [`${baseClass}-label--float-active`]:
                floatLabel && (isFocused || value),
            },
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div
        className={clsx(
          `${baseClass}-wrapper`,
          className ? `${className}-wrapper` : '',
          { [`${baseClass}-wrapper--error`]: errors },
        )}
      >
        <input
          ref={inputRef}
          className={clsx(
            `${baseClass}-input`,
            className ? `${className}-input` : '',
            className ? `${className}-input-${theme}` : '',
            `${baseClass}-input-${theme}`,
            { [`${baseClass}-input--error`]: errors },
          )}
          aria-invalid={!!errors}
          // type={type === 'number' ? 'text' : type}
          type={type}
          placeholder={floatLabel && !isFocused && !value ? '' : placeholder}
          value={value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name={name}
          disabled={isDisabled}
          step={step}
          min={min}
          max={max}
          inputMode={type === 'number' ? 'numeric' : 'text'}
        />
        {errors && (
          <div className={`${baseClass}-error`}>
            {Array.isArray(errors) ? errors.join(', ') : errors}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
