import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAuthenticatedSelector,
  propertyIdSelector,
  setAuthPropertyId,
  setAuthTokens,
} from '@/store/auth/authSlices';
import { Form, Formik, getIn } from 'formik';
import Input from '@/components/Input';
import Button from '@/components/Button';
import { fetchProfileSuccess } from '@/store/profile/profileSlices';
import { signupRequest } from '@/api';
import logo from '@/img/propsavvy-logo-with-text.png';
import * as Yup from 'yup';
import getAxiosErrors from '@/utils/getAxiosErrors';
import './SignupStyles.scss';

const baseClass = 'signup-page';

interface IValues {
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  errorMessage?: string[];
}
const initialValues: IValues = {
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  errorMessage: [],
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required').min(3),
  email: Yup.string().required('Email is required').email(),
  password: Yup.string()
    .required('Password is required')
    .min(7, 'Password must be at least 5 characters')
    .max(128, 'Password must be at most 128 characters')
    // Проверка на наличие заглавной буквы
    .matches(
      /(?=.*[A-Z])/,
      'Password must contain at least one uppercase letter',
    )
    // Проверка на наличие цифры или специального символа
    .matches(
      /(?=.*\d)|(?=.*\W+)/,
      'Password must contain at least one number or special character',
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Хук для перенаправления
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('inviteToken');
  const propertyId = useSelector(propertyIdSelector);

  React.useEffect(() => {
    if (isAuthenticated && propertyId) {
      navigate(`/properties/${propertyId}`);
    } else if (isAuthenticated && !propertyId) {
      navigate('/'); // Перенаправление на главную страницу
    }
  }, [isAuthenticated, navigate, propertyId]);

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-view`}>
        <div className={`${baseClass}-view-logo`}>
          <img src={logo} alt="logo" />
          <p className={`${baseClass}-view-logo-desc`}>
            Please enter your user information.
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            const data = inviteToken ? { ...values, inviteToken } : values;
            signupRequest(data)
              .then((res) => {
                console.log(res.data);
                const { accessToken, user, propertyId } = res.data;
                dispatch(setAuthTokens(accessToken));
                dispatch(fetchProfileSuccess(user));
                dispatch(setAuthPropertyId(propertyId));
              })
              .catch((err) => {
                setErrors(getAxiosErrors(err));
                console.log('loginErrors', getAxiosErrors(err));
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            values,
            errors,
            handleBlur,
            handleChange,
            touched,
            isSubmitting,
          }) => {
            return (
              <Form className={`${baseClass}-view-form`}>
                <div className={`${baseClass}-view-form-group`}>
                  <Input
                    floatLabel
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    name="username"
                    type="text"
                    label="Username"
                    errors={
                      getIn(errors, 'username') && getIn(touched, 'username')
                        ? getIn(errors, 'username')
                        : ''
                    }
                  />

                  <Input
                    floatLabel
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    name="email"
                    type="text"
                    label="E-mail"
                    errors={
                      getIn(errors, 'email') && getIn(touched, 'email')
                        ? getIn(errors, 'email')
                        : ''
                    }
                  />

                  <Input
                    floatLabel
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    name="password"
                    type="password"
                    label="Password"
                    errors={
                      getIn(errors, 'password') && getIn(touched, 'password')
                        ? getIn(errors, 'password')
                        : ''
                    }
                  />

                  <Input
                    floatLabel
                    className={`${baseClass}-view-form-group-password`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    type="password"
                    label="Confirm password"
                    errors={
                      getIn(errors, 'confirmPassword') &&
                      getIn(touched, 'confirmPassword')
                        ? getIn(errors, 'confirmPassword')
                        : ''
                    }
                  />

                  <div className={`${baseClass}--error`}>
                    {errors.errorMessage}
                  </div>
                  <Button
                    isDisabled={isSubmitting}
                    type="submit"
                    className={`${baseClass}-view-form-group-submit-btn`}
                    isLoading={isSubmitting}
                  >
                    {isSubmitting ? 'Registering...' : 'Register'}
                  </Button>
                </div>
                <div className={`${baseClass}-view-form-group-bottom`}>
                  <Link
                    to={`/login${inviteToken ? `?inviteToken=${inviteToken}` : ''}`}
                    className={`${baseClass}-view-form-group-bottom-create`}
                  >
                    Already member? Sign in
                  </Link>
                  <Link
                    to={'/forgot'}
                    className={`${baseClass}-view-form-group-bottom-forgot`}
                  >
                    Forgot password?
                  </Link>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Signup;
