import React, { FC } from 'react';
import clsx from 'clsx';
import './Button.scss';

const baseClass = 'Button';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: 'button' | 'submit' | 'reset';
  theme?: 'primary' | 'secondary' | 'warning' | 'danger';
}

const Button: FC<ButtonProps> = ({
  onClick,
  children,
  className,
  isDisabled = false,
  isLoading = false,
  theme = 'primary',
  type = 'button',
}) => {
  return (
    <button
      className={clsx(
        baseClass,
        className,
        `${baseClass}-${theme}`,
        className ? `${className}-${theme}` : '',
      )}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      type={type}
    >
      <span
        className={clsx(
          `${baseClass}-text`,
          className ? `${className}-text` : '',
        )}
      >
        {children}
      </span>
      {isLoading && (
        <span
          className={clsx(
            `${baseClass}-loader`,
            `${baseClass}-${theme}-loader`,
            className ? `${className}-loader` : '',
            className ? `${className}-${theme}-loader` : '',
          )}
        ></span>
      )}
    </button>
  );
};

export default Button;
