import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProperty,
  propertyLoadingSelector,
  propertySelector,
} from '@/store/property/propertySlices';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Skeleton from '@/components/Skeleton';
import { profileSelector } from '@/store/profile/profileSlices';
import Map from '@/components/Map';
import { emitWithToken } from '@/api/socket';
import { WsException } from '@/types';
import PropertyProfileDialog from '@/components/PropertyProfileDialog';
import './Property.scss';

const baseClass = 'PropertyPage';

const Property = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const property = useSelector(propertySelector);
  const isPropertyLoading = useSelector(propertyLoadingSelector);
  const profile = useSelector(profileSelector);
  const numericId = Number(id);
  const isNumeric = !isNaN(numericId);
  const [selectedUser, setSelectedUser] = useState<number | null | undefined>(
    null,
  );

  useEffect(() => {
    emitWithToken<string, WsException>('events', 'Property page')
      .then((data) => {
        console.log('event callback', data);
      })
      .catch((error: WsException) => {
        console.log('event error', error);
      });
  }, []);

  useEffect(() => {
    if (isNumeric) {
      dispatch(fetchingProperty(numericId));
    }
  }, [dispatch, id, isNumeric, numericId]);

  if (!isNumeric || (!isPropertyLoading && !property)) {
    return (
      <Card className={baseClass}>
        <h4>Invalid property ID</h4>
        <Button onClick={() => navigate('/properties')}>
          Go back to properties
        </Button>
      </Card>
    );
  }

  return (
    <Card className={baseClass}>
      <PropertyProfileDialog
        propertyId={property?.id}
        userId={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <Card.Title className={`${baseClass}--title`}>
        <Skeleton isLoading={isPropertyLoading}>{property?.name}</Skeleton>
        {profile?.id === property?.owner?.id && (
          <Button
            className={`${baseClass}--title-btn`}
            onClick={() => {
              navigate(`edit`);
            }}
          >
            Edit
          </Button>
        )}
      </Card.Title>
      <Card.Body className={`${baseClass}--body`}>
        <div className={`${baseClass}--content`}>
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>Country:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.address?.country}
              </span>
            </Skeleton>
          </div>
          {property?.address?.state && (
            <div className={`${baseClass}--row`}>
              <span className={`${baseClass}--row-label`}>State:</span>
              <Skeleton isLoading={isPropertyLoading}>
                <span className={`${baseClass}--row-value`}>
                  {property?.address?.state}
                </span>
              </Skeleton>
            </div>
          )}
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>City:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.address?.city}
              </span>
            </Skeleton>
          </div>
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>Address:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.address?.street}
              </span>
            </Skeleton>
          </div>
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>House:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.address?.houseNumber}
              </span>
            </Skeleton>
          </div>
          {property?.address?.apartmentNumber && (
            <div className={`${baseClass}--row`}>
              <span className={`${baseClass}--row-label`}>Apartment:</span>
              <Skeleton isLoading={isPropertyLoading}>
                <span className={`${baseClass}--row-value`}>
                  {property?.address?.apartmentNumber}
                </span>
              </Skeleton>
            </div>
          )}
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>Rent price:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.rentPrice}
              </span>
            </Skeleton>
          </div>
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>Type:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.type}
              </span>
            </Skeleton>
          </div>
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>Status:</span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {property?.status}
              </span>
            </Skeleton>
          </div>
          {property?.address?.zipCode && (
            <div className={`${baseClass}--row`}>
              <span className={`${baseClass}--row-label`}>Zip code:</span>
              <Skeleton isLoading={isPropertyLoading}>
                <span className={`${baseClass}--row-value`}>
                  {property?.address?.zipCode}
                </span>
              </Skeleton>
            </div>
          )}
          {property?.address?.phone && (
            <div className={`${baseClass}--row`}>
              <span className={`${baseClass}--row-label`}>Phone:</span>
              <Skeleton isLoading={isPropertyLoading}>
                <span className={`${baseClass}--row-value`}>
                  {property?.address?.phone}
                </span>
              </Skeleton>
            </div>
          )}
          <div className={`${baseClass}--row`}>
            <span className={`${baseClass}--row-label`}>
              {profile?.id === property?.owner?.id ? 'Tenant' : 'Owner'}:
            </span>
            <Skeleton isLoading={isPropertyLoading}>
              <span className={`${baseClass}--row-value`}>
                {profile?.id === property?.owner?.id ? (
                  property?.tenant?.username ? (
                    <span
                      className={`${baseClass}--row-value-profile`}
                      onClick={() => setSelectedUser(property?.tenant?.id)}
                    >
                      {property?.tenant?.username}
                    </span>
                  ) : (
                    'No tenant'
                  )
                ) : property?.owner?.username ? (
                  <span
                    className={`${baseClass}--row-value-profile`}
                    onClick={() => setSelectedUser(property?.owner?.id)}
                  >
                    {property?.owner?.username}
                  </span>
                ) : (
                  'No tenant'
                )}
              </span>
            </Skeleton>
          </div>
        </div>
        <Map
          className={`${baseClass}--map`}
          address={property?.address}
          popup={property?.name}
        />
      </Card.Body>
    </Card>
  );
};

export default Property;
