import React, { ReactNode, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import socket from '@/api/socket';

interface ProtectedRouteProps {
  isAuthenticated: boolean;
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  isAuthenticated,
}) => {
  const socketRef = useRef<Socket | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
      console.log('No token');
      return;
    }
    if (socketRef.current) {
      console.log('Socket already exists');
      socketRef.current.auth = {
        ...socket.auth,
        token: `Bearer ${token}`,
      };
      return;
    } else {
      socketRef.current = socket;
      socketRef.current.auth = {
        ...socket.auth,
        token: `Bearer ${token}`,
      };
      socketRef.current.connect();

      // socketRef.current.emit(
      //   'message',
      //   'POST custom event',
      //   (response: any) => {
      //     console.log('response', response);
      //   },
      // );
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
