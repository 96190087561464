export const isDev = process.env.NODE_ENV === 'development';

export type ProfileState = {
  profile: IUser | null;
  loading: boolean;
  error: null | string;
  serverErrors: { [key: string]: string[] } | null;
};

export type SignupDto = {
  username: string;
  email: string;
  password: string;
  inviteToken?: string | null;
};

export type SigninDto = {
  username: string;
  password: string;
  inviteToken?: string | null;
};

export type IAddress = {
  id: number;
  country: string;
  state?: string | null;
  city: string;
  street: string;
  houseNumber: string;
  apartmentNumber?: string | null;
  zipCode?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  phone?: string | null;
  createdAt: Date;
  updatedAt: Date;
};

export interface IUser {
  id: number;
  avatar?: string | null;
  username: string;
  firstname?: string | null;
  lastname?: string | null;
  email: string;
  phone: string;
  // addresses: Partial<IAddress>[];
  addresses: Partial<IAddress> | null;
  userType: 'TEST' | null;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;
  password?: string | null;
  confirmationToken?: string | null;
  isEmailConfirmed?: boolean | null;
}

export interface AuthResponse {
  accessToken: string;
  refreshToken: string;
  user: IUser;
  propertyId?: number;
}

export interface ILogoutResponse {
  message: string;
}

export type AuthErrorsResponse = {
  message: string;
  errors?: {
    [key: string]: string[]; // ключ - название поля, значение - массив сообщений об ошибках
  };
  error: string;
  statusCode: number;
};

export enum PropertyType {
  residential = 'residential',
  commercial = 'commercial',
}

export enum PropertyStatus {
  available = 'available',
  rented = 'rented',
}

export interface Invite {
  id: number;
  token: string;
  isUsed: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface IProperty {
  id: number;
  owner?: IUser | null;
  tenant?: IUser | null;
  invites?: Invite[];
  name: string;
  rentPrice: number;
  type: PropertyType;
  status: PropertyStatus;
  address: IAddress;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FetchAllPropertiesResponse {
  tenantProperties: IProperty[];
  ownedProperties: IProperty[];
}

export interface AddPropertyValues {
  name: string;
  rentPrice: number;
  type: PropertyType;
  status: PropertyStatus;
  address: Partial<IAddress>;
}

export interface UpdatePropertyRequest {
  id: number;
  data: AddPropertyValues;
}

export interface SocketResponse<T, E = string> {
  data: T;
  error?: E; // ISocketException
}

export interface WsException {
  status?: 'error' | 'exception';
  message: string;
  name?: string;
}

export interface Message {
  id: number;
  text: string;
  chat?: Chat;
  sender: IUser;
  createdAt: string;
  isRead: boolean;
}

export interface Chat {
  id: number;
  title?: string | null;
  messages: Message[];
  createdAt: string;
  lastMessageAt: string;
  participants: Message[];
  property?: IProperty | null;
}

export interface ChatsResponse extends Chat {
  message?: string | null;
}

export interface SendMessageRequest {
  text: string;
}
