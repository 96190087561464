import React, { useState, FC, useEffect, useRef } from 'react';
import 'leaflet/dist/leaflet.css';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
} from 'react-leaflet';
import L from 'leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import clsx from 'clsx';
import { IAddress } from '@/types';
import './MapStyles.scss';
import Skeleton from '@/components/Skeleton';

// Исправляем пути к иконкам Leaflet
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const baseClass = 'PropSavvyMap';

const formatAddress = (address: Partial<IAddress> | null | undefined) => {
  if (!address) return '';
  const { zipCode, country, state, city, street, houseNumber } = address;

  // Убираем пустые поля и лишние запятые
  return [zipCode, country, state, city, street, houseNumber]
    .filter(Boolean)
    .join(', ');
};

const getCity = (address: Partial<IAddress> | null | undefined) => {
  if (!address) return '';
  return address.city || '';
};

interface IMapProps {
  className?: string;
  position?: { lat: number; lng: number; alt?: number };
  address?: IAddress | undefined | null;
  popup?: string | JSX.Element;
}

const Map: FC<IMapProps> = ({
  className = '',
  position,
  popup,
  address = {
    zipCode: null,
    city: '',
    country: '',
    street: '',
    state: '',
    houseNumber: '',
    apartmentNumber: '',
  },
}) => {
  const [positionState, setPosition] = useState(position);
  const [addressState, setAddress] = useState(formatAddress(address));
  const mapRef = useRef<L.Map | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setAddress(formatAddress(address));
  }, [address]);

  // Получаем координаты по адресу
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const fetchCoordinates = async () => {
      if (!addressState) return;
      setLoading(true);
      const results = await provider.search({ query: addressState });
      if (results && results.length > 0) {
        const { x, y } = results[0];
        setPosition({ lat: y, lng: x });
      } else {
        const splitAddress = addressState.split(',');
        if (splitAddress.length > 1) {
          splitAddress.shift();
          const res = await provider.search({ query: splitAddress.join(',') });
          if (res && res.length > 0) {
            const { x, y } = res[0];
            setPosition({ lat: y, lng: x });
          }
        }
      }
      setLoading(false);
    };

    fetchCoordinates();
  }, [addressState]);

  // Обновляем центр карты
  useEffect(() => {
    if (mapRef.current && positionState) {
      mapRef.current.setView(positionState, mapRef.current.getZoom());
    }
  }, [positionState]);

  return positionState ? (
    <MapContainer
      className={clsx(baseClass, className)}
      center={positionState}
      zoom={13}
      scrollWheelZoom={true}
      ref={mapRef}
      zoomControl={false}
    >
      <ZoomControl position="topleft" />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {positionState && !isLoading && (
        <Marker position={positionState}>
          <Popup>{popup || addressState}</Popup>
        </Marker>
      )}
      <FullscreenControl forceSeparateButton position={'topright'} />
    </MapContainer>
  ) : (
    <Skeleton isLoading={isLoading} className={clsx(baseClass, className)} />
  );
};

export default Map;
