import React, { FC, useEffect } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchingProfile,
  profileSelector,
} from '@/store/profile/profileSlices';
import Button from '@/components/Button';
import './Navbar.scss';

const baseClass = 'navbar';

interface NavbarItemProps {
  to: string;
  children: React.ReactNode;
}

const NavbarItem: FC<NavbarItemProps> = ({ to, children }) => {
  return (
    <li className={`${baseClass}-menu--list-item`}>
      <NavLink
        to={to}
        className={({ isActive }) => {
          return isActive
            ? `${baseClass}-menu--list-item-link active`
            : `${baseClass}-menu--list-item-link`;
        }}
      >
        {children}
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    dispatch(fetchingProfile());
  }, [dispatch]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={baseClass}>
      <div className={`${baseClass}-menu`}>
        <ul className={`${baseClass}-menu--list`}>
          <NavbarItem to={'/'}>Dashboard</NavbarItem>
          <NavbarItem to={'/properties'}>Properties</NavbarItem>
          <NavbarItem to={'/transactions'}>Transactions</NavbarItem>
          <NavbarItem to={'/chats'}>Chats</NavbarItem>
          <NavbarItem to={'/calendar'}>Calendar</NavbarItem>
          <NavbarItem to={'/profile'}>Account</NavbarItem>
        </ul>
      </div>
      <div className={`${baseClass}-view`}>
        <div className={`${baseClass}-top-menu`}>
          {/*<Input*/}
          {/*  value={searchValue}*/}
          {/*  onChange={onChange}*/}
          {/*  className={`${baseClass}-search`}*/}
          {/*  placeholder={'Search'}*/}
          {/*/>*/}
          <div>{profile?.username}</div>
          <div className={`${baseClass}-top-menu-right`}>
            <Button onClick={auth.logout}>Logout</Button>
          </div>
        </div>
        <div className={`${baseClass}-content`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
